'use client'

import { Matomo } from '@tebuto/providers/Matomo'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import Image from 'next/image'
import { Testimonial } from './SocialProof'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'

export default function ReviewSlider({ testimonials }: { testimonials: Testimonial[] }) {
    return (
        <>
            <Matomo.ScrollTrackingAnchor sectionName={'Testimonials'} />
            <Carousel
                ssr
                swipeable
                draggable
                className="pb-24 pt-32"
                arrows={testimonials.length > 1}
                showDots={testimonials.length > 1}
                customLeftArrow={<ChevronLeftIcon className="absolute left-0 max-md:bottom-4 max-md:left-2 stroke-2 size-10 text-primary-600 z-20 cursor-pointer" />}
                customRightArrow={<ChevronRightIcon className="absolute right-0 max-md:bottom-4 max-md:right-2 stroke-2 size-10 text-primary-600 z-20 cursor-pointer" />}
                customTransition="transform 1000ms ease-in-out"
                infinite
                responsive={{
                    any: {
                        breakpoint: { max: 4000, min: 0 },
                        items: 1
                    }
                }}
            >
                {testimonials.map((testimonial, index) => (
                    <TestimoniaComponent testimonial={testimonial} key={index} />
                ))}
            </Carousel>
        </>
    )
}

function TestimoniaComponent({ testimonial }: { testimonial: Testimonial }) {
    return (
        <div className="md:px-12 h-full w-full">
            <div className="flex max-md:flex-col gap-20 items-center w-full bg-white border-gray-300 lg:p-8 p-6 md:shadow-xl rounded-xl border overflow-hidden h-full">
                {/* Content */}
                <div className="w-full h-full flex flex-col justify-between items-center relative">
                    <Image
                        src="/assets/testimonial-graphic.svg"
                        alt={'Testimonial Quotation Marks'}
                        height={250}
                        width={250}
                        className="absolute md:-left-12 md:-top-12 -top-8 left-0"
                    />
                    <div className="flex-grow flex h-full items-center justify-center">
                        <p className="lg:text-xl sm:text-lg md:leading-8 font-medium text-gray-800">{testimonial.text}</p>
                    </div>
                    <div className="mt-10 flex items-center justify-center w-full gap-4">
                        {/* Image */}
                        <div className="flex-shrink-0 flex-grow-0 size-20 flex justify-center max-w-full">
                            <Image src={testimonial.imageSrc} width={400} height={400} alt={testimonial.nameAndProfession} className="rounded-full h-full w-auto border shadow" />
                        </div>
                        <div>
                            <p className="text-landing font-semibold text-gray-800">{testimonial.nameAndProfession}</p>
                            {!testimonial.isGoogleReview && !testimonial.uri && <p className="text-sm font-medium tracking-tight text-gray-600">{testimonial.companyAndCity}</p>}
                            {!testimonial.isGoogleReview && testimonial.uri && (
                                <a href={testimonial.uri} target="_blank" className="text-sm font-medium tracking-tight text-gray-600 underline" rel="noreferrer">
                                    {testimonial.companyAndCity}
                                </a>
                            )}
                            {testimonial.isGoogleReview && (
                                <a
                                    href={'https://maps.app.goo.gl/eDWtSamiBMfY5CCB7'}
                                    target="_blank"
                                    className="text-sm font-medium tracking-tight text-gray-600 underline"
                                    rel="noreferrer"
                                >
                                    {testimonial.companyAndCity}
                                </a>
                            )}
                        </div>
                    </div>
                    <Image
                        src="/assets/testimonial-graphic.svg"
                        alt={'Testimonial Quotation Marks'}
                        height={250}
                        width={250}
                        className="absolute md:-right-12 md:-bottom-12 -bottom-8 right-0 rotate-180"
                    />
                </div>
            </div>
        </div>
    )
}
