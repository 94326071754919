'use client'

import Link from 'next/link'
import Image from 'next/image'
import { Matomo } from '@tebuto/providers/Matomo'

export default function WhoWeAre() {
    return (
        <section className="max-w-6xl mx-auto py-32 md:px-6 mt-16">
            <Matomo.ScrollTrackingAnchor sectionName={'Wer wir sind'} />
            <div className="bg-gradient-to-br from-primary-700 via-primary-600 to-primary-700 md:rounded-xl md:shadow-2xl grid grid-cols-1 lg:grid-cols-5 max-lg:divide-y divide-primary-500">
                <div className="relative lg:w-[calc(100%+4rem)] col-span-2 max-lg:h-80 max-md:h-48 max-sm:h-40 flex items-center">
                    <Image
                        alt="jonas und yannic"
                        className="absolute left-4 max-lg:left-1/2 max-lg:-translate-x-1/2 bottom-0 w-full max-lg:max-w-[32rem] lg:-ml-10"
                        src={'/assets/jonas-yannic.webp'}
                        height={400}
                        width={400}
                    />
                </div>
                <div className="lg:p-12 lg:pl-20 px-6 py-12 col-span-3 gap-4 grid">
                    <h2 className="text-primary-50 drop-shadow lg:text-4xl text-3xl font-bold max-sm:text-center">Das Team hinter Tebuto</h2>
                    <p className="text-primary-100 text-landing">
                        Wir, das sind Jonas und Yannic, sind Software-Entwickler. Wir haben Tebuto entwickelt, weil Yannics Lebensgefährtin eine Lösung für ihre psychologische
                        Praxis gesucht hat - und nichts wirklich passte.
                        <br /> <br /> Tebuto ist ein Herzensprojekt. Es gibt keine externen Gelder, keine Wachstumsziele zum Nachteil der Kunden. Mit Tebuto investierst du nicht
                        nur in mehr Zeit, sondern auch in das Versprechen, dass wir täglich an der Lösung deiner Probleme arbeiten.
                    </p>
                    <Link href="/ueber-uns" type="button" className="text-landing text-primary-100 hover:text-primary-50 underline duration-300 transition-all block ml-auto">
                        Mehr dazu
                    </Link>
                </div>
            </div>
        </section>
    )
}
