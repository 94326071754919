'use client'

import { ChevronDoubleRightIcon } from '@heroicons/react/24/outline'
import { Matomo } from '@tebuto/providers/Matomo'
import { HighlightTextBlock } from '@tebuto/typography/Highlight'
import { Heading, LandingSection } from './LandingComponents'

export default function HowDoesItWork() {
    return (
        <LandingSection id="wie-es-funktioniert">
            <Matomo.ScrollTrackingAnchor sectionName={'Wie es funktioniert'} />
            <div className="w-full max-sm:text-center">
                <p className="sm:text-sm text-xs font-semibold text-primary-600 mt-12">Mehr Zeit, weniger Stress</p>
                <Heading className="mb-8">
                    Mach deine Arbeit <HighlightTextBlock>einfacher</HighlightTextBlock>
                </Heading>
            </div>
            <p className="text-landing mb-4 text-gray-600">
                Online-Terminbuchungen sind ein echter <span className="font-semibold">Produktivitätsboost und Servicefaktor</span>. Aber oftmals lassen uns diese Tools mit vielen
                Fragen, Sorgen und Problemen zurück. Wenn du beispielsweise Termine mit Personen im Kalender findest, die du gar nicht kennst - ist das so eine Situation.
            </p>
            <p className="text-landing mb-24 text-gray-600">
                Das geht auch anders. <span className="font-semibold">Sichere dir alle Vorteile einer Online-Terminbuchung und mehr:</span>
            </p>
            <div className="grid grid-cols-1 lg:grid-cols-11 lg:gap-12 gap-4 relative">
                <div className="bg-white border border-gray-200 rounded-xl shadow z-10 lg:col-span-3">
                    <div className="p-6 flex flex-col">
                        <div className="flex items-center gap-2">
                            <p className="text-xl text-primary-700 font-bold bg-primary-100 px-2 w-max py-1 rounded-lg">1.</p>
                            <h3 className="font-medium tracking-tight text-landing !text-lg text-gray-900">Kategorien</h3>
                        </div>
                        <p className="mt-2 text-landing">Erstelle Kategorien wie Einzel-, Doppeltermin oder Erstgespräch. Lege individuell Dauer, Honorar und mehr fest.</p>
                    </div>
                </div>
                <ChevronDoubleRightIcon className="size-10 stroke-2 lg:absolute lg:left-[calc(27.27%-8px)] lg:top-1/2 lg:-translate-x-1/2 max-lg:mx-auto max-lg:rotate-90 lg:-translate-y-1/2 z-0 text-primary-600" />
                <div className="bg-white border border-gray-200 rounded-xl shadow z-10 lg:col-span-3">
                    <div className="p-6 flex flex-col">
                        <div className="flex items-center gap-2">
                            <p className="text-xl text-primary-700 font-bold bg-primary-100 px-2 w-max py-1 rounded-lg">2.</p>
                            <h3 className="font-medium tracking-tight text-landing !text-lg text-gray-900">Verfügbarkeit</h3>
                        </div>
                        <p className="mt-2 text-landing">Nutze simple Serientermine oder Einzeltermine, um festzulegen wann du welche Terminkategorien anbietest.</p>
                    </div>
                </div>
                <ChevronDoubleRightIcon className="size-10 stroke-2 lg:absolute lg:left-[calc(54.54%+3px)] lg:top-1/2 lg:-translate-x-1/2 max-lg:mx-auto max-lg:rotate-90 lg:-translate-y-1/2 z-0 text-primary-600" />
                <div className="bg-white border border-gray-200 rounded-xl shadow z-10 lg:col-span-5">
                    <div className="grid h-full md:grid-cols-2 grid-cols-1 md:divide-x max-md:divide-y">
                        <div>
                            <div className="p-6">
                                <div className="flex items-center gap-2">
                                    <p className="text-xl text-primary-700 font-bold bg-primary-100 px-2 w-max py-1 rounded-lg">A</p>
                                    <h3 className="font-medium tracking-tight text-landing !text-lg text-gray-900">Einladen</h3>
                                </div>
                                <p className="mt-2 text-landing">
                                    Lade deine Klient:innen mit einem Klick per E-Mail zu Tebuto ein. Danach können sie Termine via{' '}
                                    <a className="text-primary-600 underline" href="https://termin.tebuto.de">
                                        termin.tebuto.de
                                    </a>{' '}
                                    bei dir buchen.
                                </p>
                            </div>
                        </div>
                        <div>
                            <div className="p-6">
                                <div className="flex items-center gap-2">
                                    <p className="text-xl text-primary-700 font-bold bg-primary-100 px-2 w-max py-1 rounded-lg">B</p>
                                    <h3 className="font-medium tracking-tight text-landing !text-lg text-gray-900">Öffentlich Teilen</h3>
                                </div>
                                <p className="mt-2 text-landing">
                                    Du kannst auch Termine öffentlich und auf deiner Website anbieten. Das ist etwa für Erstgespräche oder Workshops sinnvoll.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LandingSection>
    )
}
