'use client'

import { CalendarDateRangeIcon, CalendarIcon, ChatBubbleLeftRightIcon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'
import ContactForm from './ContactForm'
import { Heading, LandingSection } from './LandingComponents'
import { Matomo } from '@tebuto/providers/Matomo'
import Link from 'next/link'

export default function Contact() {
    return (
        <LandingSection>
            <Matomo.ScrollTrackingAnchor sectionName={'Kontakt'} />
            <div className="grid lg:grid-cols-2 gap-20">
                <div className="max-w-2xl flex-shrink-0 w-full flex flex-col gap-10">
                    <Heading>Fragen offen?</Heading>
                    <p className="text-landing">
                        Kontaktiere uns per WhatsApp, Telefon, E-Mail oder Kontaktformular. Wir freuen uns auf deine Nachricht! Oder du buchst dir direkt einen Termin mit uns.
                    </p>
                    <div className="mt-20 flex flex-col gap-6">
                        <div className="flex gap-6 items-center">
                            <ChatBubbleLeftRightIcon className="size-7 text-primary-600 stroke-1" />
                            <a href={'https://wa.me/+4972146712023'} target="_blank" className="text-sm underline text-primary-600" rel="noreferrer">
                                WhatsApp-Nachricht schreiben
                            </a>
                        </div>
                        <div className="flex gap-6 items-center">
                            <PhoneIcon className="size-7 text-primary-600 stroke-1" />
                            <a href={`tel:${process.env.NEXT_PUBLIC_SUPPORT_PHONE}`} className="text-sm underline text-primary-600">
                                {process.env.NEXT_PUBLIC_SUPPORT_PHONE}
                            </a>
                        </div>
                        <div className="flex gap-6 items-center">
                            <EnvelopeIcon className="size-7 text-primary-600 stroke-1" />
                            <a href={`mailto:${process.env.NEXT_PUBLIC_HELLO_EMAIL}`} className="text-sm underline text-primary-600">
                                {process.env.NEXT_PUBLIC_HELLO_EMAIL}
                            </a>
                        </div>
                        <div className="flex gap-6 items-center">
                            <CalendarDateRangeIcon className="size-7 text-primary-600 stroke-1" />
                            <Link href={'/termin'} className="text-sm underline text-primary-600">
                                Termin vereinbaren
                            </Link>
                        </div>
                    </div>
                </div>
                <div>
                    <ContactForm />
                </div>
            </div>
        </LandingSection>
    )
}
