'use client'

import { Matomo } from '@tebuto/providers/Matomo'
import Link from 'next/link'
import { GetFreeTrialButton } from './GetFreeTrialButton'
import { LandingSection } from './LandingComponents'

export default function CTA() {
    return (
        <LandingSection className="!px-0">
            <Matomo.ScrollTrackingAnchor sectionName={'CTA'} />
            <div className="flex flex-col sm:p-12 py-12 px-6 from-primary-700 via-primary-600 to-primary-700 bg-gradient-to-br lg:rounded-xl md:shadow-2xl">
                <p className="text-primary-50 drop-shadow lg:text-4xl md:text-3xl text-2xl font-bold text-center">Mehr Zeit. Weniger Stress - Risikofrei testen.</p>
                <p className="md:text-xl text-lg mx-auto mt-4 font-medium text-center text-primary-100 max-md:pt-8">
                    Teste Tebuto 30 Tage lang kostenlos und überzeuge dich selbst.
                </p>
                <div className="mt-16 flex max-md:flex-col gap-4 justify-between max-w-3xl w-full mx-auto">
                    <Link href={process.env.NEXT_PUBLIC_THERAPISTS_AUTHORIZE_URL as string}>
                        <GetFreeTrialButton className="md:text-lg text-primary-700 font-medium bg-primary-100 text-center flex justify-center items-center rounded-lg lg:w-80 max-md:w-full px-3 py-2" />
                    </Link>
                    <a href="https://wa.me/+4972146712023" target="_blank" rel="noopener noreferrer">
                        <div className="font-medium md:text-lg text-primary-100 border-2 border-primary-100 text-center flex justify-center items-center rounded-lg lg:w-80 max-md:w-full px-3 py-2">
                            Offene Fragen? Chatte mit uns!
                        </div>
                    </a>
                </div>
            </div>
        </LandingSection>
    )
}
