'use client'

import { Matomo } from '@tebuto/providers/Matomo'
import { HighlightTextBlock } from '@tebuto/typography/Highlight'
import { BadgeEuro, BellRing, Blocks, BoxesIcon, CalendarCheck, CalendarSync, Code, Cog, HandCoins, ListTodo, MailCheck, NotebookText } from 'lucide-react'
import { ReactNode } from 'react'
import { Heading, LandingSection } from './LandingComponents'

const features = [
    {
        name: 'Privates Klientenportal',
        description:
            'Du legst fest, wer welche Termine buchen kann. Beispielsweise kannst du auch nur Erstgespräche öffentlich auf deiner Webseite freigeben und alle anderen Termine somit ausschließlich für Bestandsklient:innen verfügbar machen.',
        image: '/assets/blocks/privates-terminportal.webp'
    },
    {
        name: 'Integrationen',
        description:
            'Verbinde deine Kalender mit Tebuto, um Doppelbuchungen zu vermeiden. Verbinde dich mit Zoom oder Microsoft Teams, um bei Online-Terminen automatisch das Meeting zu erstellen und den Link zu versenden.',
        image: '/assets/blocks/integrationen.webp'
    },

    {
        name: 'DSGVO und Datenschutz',
        description:
            'Deine und die Daten deiner Klient:innen sind hochsensibel und sollten nicht in falsche Hände geraten. Wir speichern und verarbeiten Daten daher ausschließlich innerhalb der EU, erfüllen alle Anforderungen der DSGVO und setzen auf höchste Sicherheitsstandards.',
        image: '/assets/blocks/dsgvo-und-datenschutz.webp'
    },
    {
        name: 'Ausfallhonorare',
        description:
            'Vereinbare transparente Bedingungen und Gebühren bei kurzfristigen Absagen. Schütze deine Zeit und schaffe mehr Verbindlichkeit für Termine. Ob du die Gebühren erhebst oder nicht, entscheidest immer noch du.',
        image: '/assets/blocks/ausfallhonorare.webp'
    }
]

const smallFeatures = [
    {
        name: 'Zeitfenster oder Stichtag für Terminangebot',
        Icon: CalendarCheck
    },
    {
        name: 'Termine automatisch oder manuell bestätigen',
        Icon: Cog
    },
    {
        name: 'Digital absagen oder verschieben',
        Icon: BoxesIcon
    },
    {
        name: 'Einfaches Plugin für WordPress',
        Icon: Blocks
    },
    {
        name: 'Per Code auf der Website einbinden',
        Icon: Code
    },
    {
        name: 'Anonymisierter iCal-Export für Smartphone und Dritte',
        Icon: CalendarSync
    },

    {
        name: 'Automatische Terminerinnerungen',
        Icon: BellRing
    },
    {
        name: 'Individuell anpassbare Benachrichtigungen',
        Icon: MailCheck
    },
    {
        name: 'Online-Zahlungen für Termine',
        Icon: HandCoins,
        comingSoon: true
    },
    {
        name: 'Buchhaltungsintegration mit Lexoffice und SevDesk',
        Icon: BadgeEuro,
        comingSoon: true
    },
    {
        name: 'Integrierte Dokumentationsfunktion',
        Icon: NotebookText,
        comingSoon: true
    },
    {
        name: 'Wartelisten mit Benachrichtigungen',
        Icon: ListTodo,
        comingSoon: true
    }
]

export default function FeatureBoxes() {
    return (
        <LandingSection>
            <Matomo.ScrollTrackingAnchor sectionName={'Features'} />
            <div className="w-full max-sm:text-center">
                <p className="sm:text-sm text-xs font-semibold text-primary-600">Funktionen im Überblick</p>
                <Heading className="mb-12">
                    Wie wir dich <HighlightTextBlock>unterstützen</HighlightTextBlock>
                </Heading>
            </div>
            <div className="grid md:grid-cols-2 grid-cols-1 gap-8">
                {features.map(feature => (
                    <Feature key={feature.name} feature={feature} />
                ))}
            </div>
            <Heading className="py-16 text-center">... und noch viel mehr</Heading>
            <Matomo.ScrollTrackingAnchor sectionName={'Mehr Features'} />
            <div className="grid md:gap-8 gap-4 lg:grid-cols-4 md:grid-cols-3 grid-cols-2">
                {smallFeatures.map(feature => (
                    <SmallFeature key={feature.name} feature={feature} />
                ))}
            </div>
        </LandingSection>
    )
}

function Feature({ feature }: { feature: { name: string; description: string | ReactNode; image?: string } }) {
    return (
        <div className="bg-white border border-gray-200 rounded-xl shadow relative flex flex-col">
            <div className="p-8 flex-grow">
                <h3 className="font-medium tracking-tight text-landing text-gray-900 !text-lg">{feature.name}</h3>
                <p className="mt-2 text-sm font-sans antialiased">{feature.description}</p>
            </div>
            {feature.image && (
                <div className="mt-auto">
                    <img src={feature.image} alt={feature.name} className="w-full" />
                </div>
            )}
        </div>
    )
}

function SmallFeature({ feature }: { feature: { name: string; Icon: any; comingSoon?: boolean } }) {
    return (
        <div className={'bg-white border border-gray-200 rounded-xl shadow relative'}>
            {feature.comingSoon && (
                <div className="absolute -right-2 font-medium -top-2 sm:text-xs text-xxs bg-primary-100 text-primary-700 px-2 py-0.5 rounded-lg">Bald verfügbar</div>
            )}
            <div className={`sm:px-6 sm:py-4 px-3 py-2 flex flex-col gap-2 items-center ${feature.comingSoon ? ' opacity-30' : ''}`}>
                <div className="bg-primary-100 rounded-full p-2 flex-grow-0 flex-shrink-0 sm:h-[calc(2*0.5rem+1.75rem)] :h-[calc(2*0.5rem+1.25rem)]">
                    <feature.Icon className="sm:size-7 size-5 stroke-1.5 text-primary-600 drop-shadow-sm" />
                </div>
                <div className="flex items-center flex-grow h-full">
                    <h3 className="font-medium tracking-tight sm:text-sm text-xxs text-center text-gray-900 break-words hyphens-auto">{feature.name}</h3>
                </div>
            </div>
        </div>
    )
}
