'use client'

import { Matomo } from '@tebuto/providers/Matomo'
import { Heading, LandingSection } from './LandingComponents'
import Link from 'next/link'
import LinkButton from '@tebuto/buttons/Link'
import { Button } from '@tebuto/buttons/Button'
import { HighlightTextBlock } from '@tebuto/typography/Highlight'

interface RoadMapItem {
    title: string
    description: string
    readMoreLink: string
}

const roadMapItems: RoadMapItem[] = [
    {
        title: 'Zahlungen & Buchhaltung',
        description:
            'Wir integrieren die gängisten Zahlungsmethoden, wie PayPal, Google- und Apple-Pay, Kreditkarte, aber auch Rechnung, Lastschrift und Überweisung. Zusätzlich integrieren wir gängige Buchhaltungstools, um dir noch mehr Arbeit abzunehmen.',
        readMoreLink: '/roadmap/zahlungen-und-buchhaltung'
    },
    {
        title: 'Warteliste',
        description:
            'Mit der Wartelisten-Funktion möchten wir dir helfen, deine Kapazität optimal zu nutzen und fair zu managen. Gleichzeitig wollen wir dich entlasten, in dem wir automatisch bei den Wartenden nachhaken, ob der Platz noch benötigt wird.',
        readMoreLink: '/roadmap/warteliste'
    },
    {
        title: 'Dokumentation',
        description:
            'Dokumentation ist das A und O in der Beratung. Wir möchten dir helfen, deine Dokumentation zu vereinfachen und zu digitalisieren. Dafür planen wir eine einfache und intuitive Dokumentationsfunktion. Ohne Abstriche beim Datenschutz.',
        readMoreLink: '/roadmap/dokumentation'
    }
]

export default function RoadMapFeatures() {
    return (
        <LandingSection>
            <Matomo.ScrollTrackingAnchor sectionName={'Roadmap'} />
            <div className="w-full max-sm:text-center">
                <p className="sm:text-sm text-xs font-semibold text-primary-600">Unsere Roadmap</p>
                <Heading>
                    Bestimme die Zukunft <HighlightTextBlock>deiner</HighlightTextBlock> Software.
                </Heading>
            </div>
            <div className="pt-12">
                <p className="text-landing text-gray-600">
                    Uns ist es wichtig, nicht irgendeine Software zu entwickeln, sondern <b className="text-landing">deine Software</b>. Wir möchten dich bei deiner Arbeit
                    bestmöglich entlasten. Dafür benötigen wir <b className="text-landing">deine Mithilfe.</b> Auf unserer Roadmap kannst du Ideen, Wünsche und Anliegen mit uns
                    teilen, damit wir diese berücksichtigen und gemeinsam Lösungen entwickeln können. Wir freuen uns auf deine Anregungen!
                </p>
                <div className="mt-6 w-full flex justify-end">
                    <Button className="w-max" href="/roadmap">
                        Zur Roadmap
                    </Button>
                </div>
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-6 mt-24">
                {roadMapItems.map((roadMapItem, index) => (
                    <RoadMapItem roadMapItem={roadMapItem} key={index} />
                ))}
            </div>
        </LandingSection>
    )
}

function RoadMapItem({ roadMapItem }: { roadMapItem: RoadMapItem }) {
    return (
        <div className="bg-white flex flex-col justify-between gap-4 px-6 py-8 rounded-3xl shadow-md border-gray-300 border">
            <h2 className="font-medium text-gray-800 md:text-xl text-lg">{roadMapItem.title}</h2>
            <p className="text-landing flex-grow h-full">{roadMapItem.description}</p>
            <a href={roadMapItem.readMoreLink} className="text-primary-600 text-landing font-medium self-end">
                Mehr dazu
            </a>
        </div>
    )
}
